<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3">
                    <div>
                        <p class="pb2 f3 b">
                            Inventory Transfer
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickTooltipIcon"
                                    @click="toggleTooltip = true"
                                    style="cursor: pointer"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                            This shows an overview of all your customers, including suppliers, debtors and investors. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>
                    <router-link :to="{ name: 'StockTransfers' }">Back</router-link>
                </div>
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Transfer Order</h4>
                        <p>
                            Specify the items that are being transferred, the quantity of each item, 
                            and the location to which the items are being transferred
                        </p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Source Business</label>
                                <select
                                    class="form-sign"
                                    v-model="state.sourceBusiness"
                                    name="sourceBusiness"
                                    id="sourceBusiness"
                                    style="padding: 10px"
                                >
                                    <option value="">Select source business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Destination Business</label>
                                <select
                                    class="form-sign"
                                    v-model="state.destinationBusiness"
                                    name="destinationBusiness"
                                    id="destinationBusiness"
                                    style="padding: 10px"
                                >
                                    <option value="">Select destination business</option>
                                    <option v-for="business in state.allBusinesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Reference</label>
                                <input
                                    class="form-sign"
                                    v-model="state.reference"
                                    name="reference"
                                    id="reference"
                                    style="padding: 10px"
                                    disabled
                                />
                            </div>
                            <div class="flex flex-column pb3 form-spacing">
                                <label class="pb2 font-w2">Select a date</label>
                                <input
                                    type="date"
                                    class="form-sign"
                                    v-model="state.date"
                                    name="date"
                                    id="date"
                                    style="padding: 10px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l">
                        <h4>Products</h4>
                        <p>Choose products to add to this transfer</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <div v-if="state.products.length" class="pb3">
                            <table class="table table-bordered table-striped" cellspacing="0">
                                <caption></caption>
                                <thead>
                                    <tr>
                                        <th width="15%">Product</th>
                                        <th>Inventory at source</th>
                                        <th>Inventory at destination</th>
                                        <th>Quantity</th>
                                        <th>Cost price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th>Total price ({{ $store.state.Settings?.currency || 'NGN' }})</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, index) in state.products" :key="product.id">
                                        <td class="vertical-align-middle">
                                            <div>{{ product.name }}</div>
                                            <div><small>{{ product.barcode }} {{ product.sku }}</small></div>
                                        </td>
                                        <td class="vertical-align-middle">{{ product.source_qty }}</td>
                                        <td class="vertical-align-middle">{{ product.destination_qty }}</td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2" 
                                                v-model="product.quantity"
                                                :max="state.allowZeroStockLevel ? 10000 : product.source_qty"
                                                @input="(e) => watchTransferQtyChange(e.target.value, product.source_qty, index)"
                                            />
                                        </td>
                                        <td class="vertical-align-middle">
                                            <input 
                                                type="number" 
                                                class="product-input-2"  
                                                v-model="product.price"
                                            />
                                        </td>
                                        <td class="vertical-align-middle">{{ formatAmount(product.quantity * product.price, $store.state.Settings?.currency || 'NGN') }}</td>
                                        <td class="tc vertical-align-middle font-w2" width="30">
                                            <a href="" @click.prevent="removeProduct(index)" v-tippy="'Remove'">
                                                <img :src="require('@/assets/images/delete.svg')" alt="remove" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="input-group">
                            <span class="input-group-label">
                                <font-awesome-icon icon="fa-solid fa-search" class="fa-2x" />
                            </span>
                            <v-select
                                ref="filterProduct"
                                label="name"
                                placeholder="Search for product with Name, SKU or scan Barcode"
                                class="pv2"
                                v-model="state.filterProduct"
                                :filterable="false"
                                :options="state.options"
                                @search="onSearchProduct"
                                @option:selected="item => selectProduct(item)"
                                @option:deselected="item => deselectProduct(item)"
                                :clear-search-on-select="true"
                                :disabled="state.sourceBusiness === '' || state.destinationBusiness === '' || state.sourceBusiness === state.destinationBusiness"
                            >
                            <template #open-indicator="{ attributes }">
                                <span v-bind="attributes"></span>
                            </template>
                            <template #no-options="{}">
                                Type to search product..
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="flex justify-start">
                    <div class="w-100 w-30-l pr3">
                        <h4>Tags</h4>
                        <p>Label this purchase order for easy identification</p>
                    </div>
                    <div class="w-100 w-70-l">
                        <label>Tags</label>
                        <vue-tags-input class="mt2 w-100"
                            v-model="state.tag"
                            :tags="state.tags"
                            :validation="state.validation"
                            :autocomplete-items="state.myTags"
                            :max-tags="5"
                            placeholder="Add or select tag"
                            @tags-changed="newTags => state.tags = newTags"
                        />
                    </div>
                </div>
                <hr />
                <div class="flex justify-end items-center mt-3">
                    <button v-if="state.status === 'draft'"
                        class="btn mr2"
                        @click="handleTransferStock('draft')"
                        style="background-color: white; border: 1px solid rgb(19, 44, 140); color: rgb(19, 44, 140);"
                        :disabled="state.isProcessing || state.sourceBusiness === '' || state.destinationBusiness === '' || state.sourceBusiness === state.destinationBusiness ||state.products.length <= 0"
                    >
                        <span>{{ state.isProcessing ? 'Processing...' : 'Save as Draft' }}</span>
                    </button>
                    <button 
                        @click="handleTransferStock('pending')"
                        :disabled="state.isProcessing || state.sourceBusiness === '' || state.destinationBusiness === '' || state.sourceBusiness === state.destinationBusiness ||state.products.length <= 0"
                    >
                        <span v-if="state.formMode === 'create'">{{ state.isProcessing ? 'Processing...' : 'Submit' }}</span>
                        <span v-else>{{ state.isProcessing ? 'Processing...' : 'Confirm' }}</span>
                    </button>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import VueTagsInput from "@sipec/vue3-tags-input"
import AppWrapper from '@/layout/AppWrapper'
import Spinner from '@/components/Spinner'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'TransferStock',
    components: { AppWrapper, Spinner, vSelect, VueTagsInput },

    setup() {
        const toggleTooltip = ref(false)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const userData = computed(() => store?.state?.Auth?.userData)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = () => ({
            isProcessing: false,
            sourceBusiness: '',
            destinationBusiness: '',
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            date: moment().format('YYYY-MM-DD'),
            products: [],
            allBusinesses: [],
            businesses: [],
            filterProduct: '',
            status: 'draft',
            formMode: 'create',
            allowZeroStockLevel: false,
            tag: '',
            tags: [],
            myTags: [],
            validation: [
                {
                    classes: 'min-length',
                    rule: tag => tag.text.length < 3,
                },
                {
                    classes: 'no-numbers',
                    rule: /^([^0-9]*)$/,
                },
                {
                    classes: 'avoid-item',
                    rule: /^(?!Cannot).*$/,
                    disableAdd: true,
                },
                {
                    classes: 'no-braces',
                    rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
                }
            ],
        })

        const state = reactive(initialState())

        watch(
            () => [state.sourceBusiness, state.destinationBusiness],
            (currValue, prevValue) => {
                if (currValue !== prevValue) { 
                    // state.products = []
                }
        })

        const closeTooltip = () => {
            toggleTooltip.value = false
        }

        const onSearchProduct = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL', business: state.sourceBusiness }).then(data => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const selectProduct = (item) => {
            const index = state.products.findIndex((product) => product.id === item._id)
            if (index >= 0) {
                state.products[index].quantity = state.products[index].quantity + 1
                state.options = []
                state.filterProduct = ''
                item.name = 'Search for product with Name, SKU or scan Barcode'
            } else {
                store.dispatch('Inventory/getTransferStockDestinationQuantity', { productName: item.name, sku: item.sku, business: state.destinationBusiness })
                    .then(data => {
                        state.products.push({
                            name: item.name,
                            product: item._id,
                            price: item?.buyingPrice || 0,
                            source_qty: item?.qty_in_stock || 0,
                            destination_qty: data,
                            quantity: 1,
                            instock: item?.instock || 0,
                            sku: item?.sku || '',
                            barcode: item?.barcode || '',
                            id: item._id
                        })

                        state.options = []
                        state.filterProduct = ''
                        item.name = 'Search for product with Name, SKU or scan Barcode'
                    })
            }

        }

        const deselectProduct = (product, item) => {
            
        }

        const resetState = () => {
            state.destinationBusiness = ''
            state.reference = new ShortUniqueId({ length: 6 }).randomUUID()
            state.date = moment().format('YYYY-MM-DD')
            state.products = []
            state.options = []
        }

        const watchTransferQtyChange = (value, max, index) => {
            if (state.allowZeroStockLevel && value > max) {
                state.products[index].quantity = max
            }
        }

        const removeProduct = (index) => {
            const { products } = state
            const productToDelete = products.splice(index, 1)
            state.products = products.filter(element => element !== productToDelete[0])
        }

        const handleTransferStock = (status = 'draft') => {
            Swal.fire({
                    title: status === 'draft' ? `Confirm Save as Draft` : `Confirm Stock Transfer`,
                    text: status === 'draft' ? `Are you sure you want to save this stock transfer as draft?` : `Are you sure you want to transfer these stocks?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue!',
                    denyButtonText: `No, Cancel!`
                }).then(result => {
                    if (result.isConfirmed) {
                        state.isProcessing = true
                        const sourceBusiness = state.businesses.filter(business => business.org === state.sourceBusiness)[0]
                        const destinationBusiness = state.businesses.filter(business => business.org === state.destinationBusiness)[0]
                        const data = {
                            sourceBusiness: { id: sourceBusiness.org, name: sourceBusiness.name, email: sourceBusiness.orgEmail },
                            destinationBusiness: { id: destinationBusiness.org, name: destinationBusiness.name, email: destinationBusiness.orgEmail },
                            reference: state.reference,
                            date: state.date,
                            products: state.products,
                            status: status,
                        }

                        data.totalItems = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        data.totalValue  = state.products.reduce((prevVal, currValue) => {
                            prevVal += parseFloat(currValue.price) * parseFloat(currValue.quantity)
                            return prevVal
                        }, 0)

                        data.tags = state.tags.map(tag => {
                            return tag.text
                        })

                        if (state.formMode === 'create') {
                            store.dispatch(`Inventory/transferStock`, data)
                                .finally(() => {
                                    resetState()
                                    state.isProcessing = false
                                    router.push({ name: 'StockTransfers' })
                                })
                        } else {
                            const { id } = route.query
                            store.dispatch(`Inventory/updateTransferStock`, { id, data })
                                .finally(() => state.isProcessing = false)
                        }
                                    
                    }
                })
        }

        const getTransferStock = (id) => {
            store.dispatch('Inventory/getTransferStock', { id }).then(resp => {
                const { status, data } = resp
                if (status) {
                    state.sourceBusiness = data?.sourceBusiness?.id || ''
                    state.destinationBusiness = data?.destinationBusiness?.id || ''
                    state.reference = data?.reference
                    state.date = data?.date
                    state.products = data?.products
                    state.status = data?.status
                    state.formMode = 'edit'
                }
            })
        }

        onMounted(() => {
            store.dispatch('Settings/getAccountSettings').then(data => {
                if (data.status) {
                    state.allowZeroStockLevel = data?.data?.allowZeroStockLevel || false
                }
            })
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.businesses = resp.data
                    state.sourceBusiness = userData.value?.orgId || ''
                }
            }).finally(() => {
                const { id } = route.query
                if (id) {
                    getTransferStock(id)
                }
            })
            
            store.dispatch('Settings/getAllBusinesses').then(resp => {
                if (resp.status) {
                    state.allBusinesses = resp.data
                }
            })
            
            store.dispatch('Tag/getTags').then(data => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })

            window.addEventListener('click', event => {
                const tooltipIcon = document.querySelector('.clickTooltipIcon')

                if (
                    !(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) &&
                    toggleTooltip.value === true
                ) {
                    closeTooltip()
                }
            })
        })

        return {
            state,
            userData,
            role,
            rolePermissions,
            toggleTooltip,
            onSearchProduct,
            selectProduct,
            deselectProduct,
            handleTransferStock,
            productEntrySearchList,
            formatAmount,
            watchTransferQtyChange,
            removeProduct,
        }
    }
}
</script>

<style>
    .vs__selected-options {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
    }
    .input-group {
        position: relative;
    }
    .input-group-label {
        position: absolute;
        width: 10px;
        top: 23px;
        left: 15px;
        cursor: pointer;
        z-index: 10;
    }
    .fa-2x {
        font-size: 1.3em;
    }
    .vue-tags-input {
        max-width: 100% !important;
    }
</style>
